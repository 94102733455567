import React from "react"

import SideLayout from "../components/Sidelayout"
import SEO from "../components/seo"

export default function About() {
  return (
    <SideLayout>
      <SEO title="about " />

      <h2>Cosa è milano.dev?</h2>
      <p>
        milano.dev è un portale dedicato agli sviluppatori che vivono o lavorano
        a Milano, dove potranno trovare aggregati, eventi, meetups, workshops e
        diverse attivita locali legate al knowledge-sharing, mentoring e al
        mondo dello sviluppo in generale.
      </p>

      <p>
        milano.dev ha come obbiettivo dare visibilità al valore creato dalle
        communities.
      </p>

      <p>
        milano.dev esiste prima di tutto per rendere semplice godersi cio' che
        milano ha da offrire, così da non dover più dire
      </p>
      <p
        css={{
          padding: "10px",
          fontSize: "1.1em",
          fontWeight: "300px",
          display: "inline-block",
          fontStyle: "italic",
        }}
      >
        "non sapevo di questo evento! sarei venuto"
      </p>

      <h2>Cosa NON è milano.dev</h2>
      <p>
        milano.dev non vuole prendere il posto delle communities già esistenti o
        dei loro organizzatori, ne tanto meno creare noie e disagi agli stessi
      </p>

      <p>
        milano.dev non ha intenzione di creare un ecosistema chiuso con gli
        "speakers di milano.dev" o "eventi di milano.dev" o "le communities di
        milano.dev"
      </p>

      <h2>whois</h2>
      <p>
        Il progetto è stato creato da{" "}
        <i>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/kandros5591"
          >
            Jaga Santagostino
          </a>
        </i>
      </p>
    </SideLayout>
  )
}
